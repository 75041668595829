/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;
// let headers = new Headers();
// headers.set('content-type', 'application/json; charset=utf-8');
// headers.set('Accept', 'application/json');
// headers.set('Origin', window.location.hostname);
// headers.set('Host', 'api.huuray.com');


export type OpenAPIConfig = {
    BASE: string;
    VERSION: string;
    WITH_CREDENTIALS: boolean;
    CREDENTIALS: 'include' | 'omit' | 'same-origin';
    TOKEN?: string | Resolver<string> | undefined;
    USERNAME?: string | Resolver<string> | undefined;
    PASSWORD?: string | Resolver<string> | undefined;
    HEADERS?: Headers | Resolver<Headers> | undefined;
    ENCODE_PATH?: ((path: string) => string) | undefined;
};

export const OpenAPI: OpenAPIConfig = {
    BASE: '',
    VERSION: '4',
    WITH_CREDENTIALS: true,
    CREDENTIALS: 'include',
    TOKEN: '43b373c4-6fdd-4d2b-b18b-0bdb84a44e74',
    USERNAME: 'kamel@tooodooo.com',
    PASSWORD: 'Huuray@1234',
    HEADERS: {
        'Referer': window.location.host,
        'Origin': window.location.host + '/',
        'Host': 'api.huuray.com',
        'Content-Type': 'application/json; charset=utf-8',
        'Sec-Fetch-Mode':'no-cors',
    },
    ENCODE_PATH: undefined,
};
