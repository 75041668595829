import React from 'react';
import LoginFormWrapper from 'components/organisms/form-wrappers/LoginFormWrapper';
import { LOGIN } from 'constants/routes';
import landingImage from 'assets/images/landingBg.jpg';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/landing/UserActions.module.scss';

/**
 * Page component used to render login page
 *
 * @constructor
 */
const LoginPage = () => {
  const { userActionPage, formWrapper } = componentStyle;
  const { contentCentered } = coreStyle;

  return (
    <>
      <section
        className={`${userActionPage} ${contentCentered}`}
        style={{
          backgroundImage: `linear-gradient(133deg, rgba(54,185,146,.3) 0%, rgba(54,185,146,.3) 35%, rgba(112,198,132,.3) 73%, rgba(170,211,117,.3) 100%),  url(${landingImage})`
        }}
        id={LOGIN}
      >
        <div className={formWrapper}>
          <LoginFormWrapper isOnboardingFlow={false} />
        </div>
      </section>
    </>
  );
};

export default LoginPage;
