/* eslint-disable quotes */
import React from 'react';
import { useLocation } from 'react-router-dom';
import GenericFormBuilder from 'components/organisms/form-wrappers/GenericFormBuilder';
import WallCreateAccountSubmit from 'components/molecules/forms/updateAccountInformation/WallCreateAccountSubmit';
import { useInitialAccountChecks } from 'hooks/user/useInitialAccountChecks';
import { submitUpdatePersonalInformation } from 'store/actions/formActions';

/**
 * Organism component used to render wall update personal information form
 * @param fullAvatar
 * @param croppedAvatar
 * @param avatarConfig
 * @param linkedEmails
 * @param userEmail
 * @param personalInformation
 * @param setFormLoading
 * @param fields
 * @param history
 * @param formLoading
 * @param setImageError
 * @param userData
 * @param safeToDelete
 * @param imageError
 * @constructor
 */
const UpdateAccountForm = ({
  fullAvatar,
  croppedAvatar,
  avatarConfig,
  linkedEmails,
  userEmail,
  personalInformation,
  setFormLoading,
  fields,
  history,
  setImageError,
  userData,
  safeToDelete,
  imageError
}) => {
  const { state } = useLocation();
  const { initialErrors, initialTouched } = useInitialAccountChecks(state);

  return (
    <GenericFormBuilder
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      formAction={(values, props) => {
        submitUpdatePersonalInformation(
          { fullAvatar, croppedAvatar, avatarConfig },
          { values: { ...values }, linkedEmails, email: userEmail, personalInformation },
          props,
          setFormLoading,
          setImageError,
          userData,
          safeToDelete
        );
      }}
      formDeclaration={fields}
      formSlot={form => (
        <WallCreateAccountSubmit
          {...{
            form,
            history,
            imageError
          }}
        />
      )}
      disableSubmit={true}
    />
  );
};

export default UpdateAccountForm;
