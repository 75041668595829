import React from 'react';

import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';
import Button from 'components/atoms/ui/Button';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { TIME_OF_ALLOCATION, END_OF_PROGRAM, AGREEMENT_ADMIN, SPEND_TYPE } from 'constants/wall/points';

import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/wall/PointsConversion.module.scss';

/**
 * Cta used to trigger the conversion process pof points
 * @param cube
 * @param points
 * @param onStartCashout
 * @param canConvert
 * @constructor
 */
const PointsConversionCta = ({ cube, points, onStartCashout, canConvert }) => {
  const { colorMainButtonsBackground, colorMainButtonText } = useSelectedProgramDesign();
  const { mt4, btnLg } = coreStyle;
  const spendType = SPEND_TYPE[cube && cube.spendType];
  if (!points) {
    return null;
  }

  if (spendType === TIME_OF_ALLOCATION || (spendType === END_OF_PROGRAM && canConvert)) {
    return (
      
      <DynamicFormattedMessage
        tag={Button}
        className={`${mt4} ${btnLg} ${componentStyle[TIME_OF_ALLOCATION]}`}
        id="wall.beneficiary.points.cashOut"
        onClick={() => onStartCashout(true)}
        customStyle={{
          color: colorMainButtonText,
          background: colorMainButtonsBackground
        }}
      />

      
    );
  }
  if (spendType === END_OF_PROGRAM && !canConvert) {
    return (
      <DynamicFormattedMessage
        tag={Button}
        className={`${mt4} ${btnLg} ${componentStyle[spendType]}`}
        id="wall.beneficiary.points.atEndOfProgram"
        customStyle={{
          color: colorMainButtonText,
          background: colorMainButtonsBackground
        }}
      />
    );
  }
  if (spendType === AGREEMENT_ADMIN) {
    return (
      <DynamicFormattedMessage
        tag={Button}
        className={`${mt4} ${btnLg} ${componentStyle[spendType]}`}
        id="wall.beneficiary.points.askConversion"
        onClick={() => onStartCashout(true)}
        customStyle={{
          color: colorMainButtonText,
          background: colorMainButtonsBackground
        }}
      />
    );
  }

  return null;
};

export default PointsConversionCta;
