import {
  AGENDA_WALL,
  COMMUNICATION,
  DASHBOARD_WALL,
  DECLARATIONS_WALL,
  NOTIFICATIONS,
  ONBOARDING_GENERIC,
  PERSONAL_INFORMATION,
  SUBSCRIPTION,
  SUCCESS,
  USER_DECLARATIONS,
  USERS_WALL,
  WELCOME_PAGE_ROUTE
} from 'constants/routes';
import { POINTS_ENDPOINT, RANKING } from 'constants/api';
import { REWARDS } from 'constants/wall/launch';
import { PAYMENT } from 'constants/wall/settings';

export enum PAGE_TITLES {
  DEFAULT = 'yooowin',
  ONBOARDING = 'welcome!',
  PROGRAMS_WALL = ' wall',
  SETTINGS_ACCOUNT = 'account',
  SETTINGS_ADMINISTRATORS = 'administrators',
  SETTINGS_PAYMENT = 'payment',
  SETTINGS_GDPR = 'GDPR',
  SETTINGS_CHANGE_PASSWORD = 'change password',
  PROGRAMS_LAUNCH = 'launch',
  PROGRAMS_QUICK_LAUNCH = 'quick launch',
  ONBOARDING_PROGRAMS = 'programs',
  COMMUNICATION = 'communication'
}

export const SETTINGS_PAGE_TITLES = [
  PAGE_TITLES.SETTINGS_ACCOUNT,
  PAGE_TITLES.SETTINGS_ADMINISTRATORS,
  PAGE_TITLES.SETTINGS_PAYMENT,
  PAGE_TITLES.SETTINGS_GDPR,
  PAGE_TITLES.SETTINGS_CHANGE_PASSWORD
];

export const pagesLocationPathname = [
  WELCOME_PAGE_ROUTE,
  ONBOARDING_GENERIC,
  REWARDS,
  USERS_WALL,
  PERSONAL_INFORMATION,
  SUBSCRIPTION,
  `${PAYMENT}-method`,
  `${PAYMENT}/${SUCCESS}`,
  USER_DECLARATIONS,
  DECLARATIONS_WALL,
  DASHBOARD_WALL,
  POINTS_ENDPOINT,
  RANKING,
  NOTIFICATIONS,
  AGENDA_WALL,
  COMMUNICATION
];

export const LAUNCH_FIRST_STEP = 'program/1';
