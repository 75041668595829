import React, { useEffect, useRef, useState, useCallback, useContext } from "react";

import GeneralBlock from "components/molecules/block/GeneralBlock";
import { DynamicFormattedMessage } from "components/atoms/ui/DynamicFormattedMessage";
import { HTML_TAGS } from "constants/general";
import { useSelectedProgramDesign } from "hooks/wall/ui/useSelectedProgramColors";

import coreStyle from "sass-boilerplate/stylesheets/style.module.scss";
import wallStyle from "sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss";
import wallBaseStyle from "sass-boilerplate/stylesheets/components/wall/WallBaseBlock.module.scss";
import widgetStyle from "sass-boilerplate/stylesheets/components/wall/widgets/Widget.module.scss";
import { WALL_BLOCK } from "constants/wall/blocks";
import { useWallSelection } from "hooks/wall/useWallSelection";
import { CHALLENGE, FREEMIUM } from "constants/routes";
import { PROGRAM_TYPES } from "constants/wall/launch";
import useSelectedProgram from "hooks/wall/useSelectedProgram";
import postTabStyle from "sass-boilerplate/stylesheets/components/wall/PostTabs.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { useUserRole } from "hooks/user/useUserRole";
import { getUserAuthorizations, isAnyKindOfAdmin, isUserBeneficiary } from "services/security/accessServices";
import { useCheckAndRedirect } from "hooks/user/useCheckAndRedirect";
import useProgramDetails from "hooks/programs/useProgramDetails";
import componentStyle from "sass-boilerplate/stylesheets/components/landing/FeatureElement.module.scss";

import iaStyle from "sass-boilerplate/stylesheets/components/ia/AiStyle.module.scss";
import { UserContext } from "components/App";
import { PickIntentExtension } from "./extentions";

const { cardContent, cardIcon, designVisible } = componentStyle;


const ContactUsWidget = ({ modifyProgramDesign }) => {
  const { pb2, withBoldFont, withGrayAccentColor, textCenter, pointer, widthFull } = coreStyle;
  const { widgetTitle, widgetTextBody, widgetContact, blockContactUs } = widgetStyle;
  const { colorWidgetTitle } = useSelectedProgramDesign();
  const { selectedProgramId } = useWallSelection();
 
  const {iaWidgetStyle} = iaStyle;
  

  const selectedProgram = useSelectedProgram();
  const isFreemium = selectedProgram && selectedProgram.programType === PROGRAM_TYPES[FREEMIUM];
  const { postIcon, modifyProgram } = postTabStyle;
  const role = useUserRole();
  const userRights = getUserAuthorizations(role);
  const isAnyAdmin = isAnyKindOfAdmin(userRights);
  const isBeneficiary = isUserBeneficiary(role);
  const bootRef = useRef(null);
  const [currentProgramId, setCurrentProgramId] = useState<number | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const { onRewardsRedirect } = useCheckAndRedirect();
  const programDetail = useProgramDetails(selectedProgramId);
  const [showcog,setShowCog] = useState(true);
  const { userData } = useContext(UserContext);
  const showBubbleBox = () => {
    const botIa = document.getElementById("voiceflow-chat")
    const voiceflowChat = document.getElementById("voiceflow-chat")

    if (voiceflowChat && botIa) {
      // botIa.append(voiceflowChat)
      const position= botIa.getBoundingClientRect();
      const voiceflowChat = document.getElementsByClassName("vfrc-widget--launcher c-PJLV");
      window.voiceflow?.chat?.show();
    }


  }
  const showHideIa = (iaProjectId) => {
    const botIa = document.getElementById("voiceflow-chat-frame");
    console.log("id & selectedid :" + selectedProgramId, iaProjectId)
    const sheet = new CSSStyleSheet();
    document.adoptedStyleSheets = [sheet];
    sheet.replaceSync(`.c-ivtLaR-ftPdvq-withChat-false > .c-PJLV {bottom: 150px !important}`)
    if (iaProjectId && selectedProgramId && !isAnyAdmin) {
      // const posY = botIa.getBoundingClientRect().bottom / 2;
      // const posX = botIa.getBoundingClientRect().right / 2;
      // Load the Voiceflow chat widget if projectId is present
      setShowCog(false);
      const script = document.createElement("script");
      script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      script.async = true;
      script.onload = () => {
        window.voiceflow?.chat?.load({
          verify: { projectID: iaProjectId },
          url: "https://general-runtime.voiceflow.com",
          versionID: "production",
          userID: userData.uuid, 
          launch: {
            event: {type:"launch", payload: {
              user_name: userData.firstName,
              challenge_id: selectedProgramId,
              company_id: programDetail.programDetails.iaCompany.companyName  
            }
            }
          } ,
          allowDangerousHTML: true,
          assistant: {
            color: `${selectedProgram.design.colorSidebar}`,
            stylesheet: 'boot-style.css',
            extensions: [
              PickIntentExtension
            ],
          }          
        });
        window.voiceflow?.chat?.show();
        // showBubbleBox();
      };
      
      botIa.appendChild(script)
      // document.body.appendChild(script);

    } else {
      // If there's no projectId, hide the chat bubble
      window.voiceflow?.chat?.destroy();
      setShowCog(true)
    }
  }

  const updateProgramId = useCallback((newProgramId) => {
    if (newProgramId !== undefined) {
      setCurrentProgramId(newProgramId);
    } else {
      setCurrentProgramId(null);
    }
  }, []);

  useEffect(() => {
    if (selectedProgramId !== currentProgramId) {
      updateProgramId(selectedProgramId);
    }
  }, [selectedProgramId, currentProgramId, updateProgramId]);

  useEffect(() => {
    if (!programDetail.isLoading && userData && userData.uuid) {
      // console.log(`API call for Program ID: ${currentProgramId}`);
      // console.log(programDetail);

      // Get iaProjectId from program details
      const newProjectId = programDetail.programDetails?.iaCompany?.iaProjectId || null;
      showHideIa(newProjectId)
    }
    return () => {
      showHideIa(null);
    }
  }, [currentProgramId, programDetail.isLoading, programDetail.programDetails]);

  // useEffect(() => {
  //   console.log("id & selectedid :" + selectedProgramId , projectId)
  //   if (projectId && selectedProgramId) {
  //     // Load the Voiceflow chat widget if projectId is present
  //     const script = document.createElement("script");
  //     script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
  //     script.async = true;
  //     script.onload = () => {
  //       window.voiceflow?.chat?.load({
  //         verify: { projectID: '664ef2559a1966af90eeadf4' },
  //         url: "https://general-runtime.voiceflow.com",
  //         versionID: "production",
  //         assistant: {
  //           color: `${selectedProgram.design.colorSidebar}`,
  //         },
  //       });
  //       window.voiceflow?.chat?.show();
  //     };
  //     document.body.appendChild(script);

  //   } else {
  //     // If there's no projectId, hide the chat bubble
  //     window.voiceflow?.chat?.hide();
  //   }

  //   // Cleanup function to hide the chat bubble on unmount
  //   return () => {
  //     window.voiceflow?.chat?.hide();
  //   };
  // }, [projectId,selectedProgramId, selectedProgram?.design?.colorSidebar]);

  const onClick = () => {
    if(type==4){modifyProgramDesign(WALL_BLOCK.SETTINGS_BLOCK,FREEMIUM); console.log("DKHEL")}
    else {modifyProgramDesign(WALL_BLOCK.SETTINGS_BLOCK,CHALLENGE); console.log("MADKHELCH")}
    
  };

  // Conditional ID assignment based on projectId and selectedProgramId presence
  console.log(programDetail)
  
  const newProjectId= programDetail.programDetails?.iaCompany?.iaProjectId;
  const newAiName = programDetail.programDetails?.iaCompany?.iaName;
  const type = programDetail.programDetails?.type;
 
  let widgetTitleId = newProjectId && selectedProgramId ? "wall.user.block.bootDescription" : "wall.settings.defaultTitle";
  const widgetTextBodyId = newAiName && selectedProgramId ? newAiName : "wall.settings.defaultBody";
  if(isAnyAdmin && selectedProgramId){
    widgetTitleId = "wall.admin.block.design"
  }
  // console.log(newProjectId && selectedProgramId)
  // console.log("BRRRRRRRRRRRRR"+widgetTitleId)


  return (
    <div ref={bootRef}>
      <GeneralBlock className={`${withGrayAccentColor} ${widgetContact} ${textCenter} ${wallStyle.hideBlockMobile} ${blockContactUs}`}>
        <div className={`${wallBaseStyle.modifyBlockContainer}`}>
          <DynamicFormattedMessage
            className={`${withBoldFont} ${widgetTitle}`}
            id={widgetTitleId}
            tag={HTML_TAGS.P}
            style={{ color: colorWidgetTitle, marginLeft: "10px" }}
          />
          {/* {selectedProgramId && isAnyAdmin && isFreemium && (
            <div className={`${postIcon} ${modifyProgram}`} onClick={onClick} />
          )} */}
        </div>
        <div id="voiceflow-chat" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80%'}}>
          {(!projectId || !selectedProgramId) && <div className={`${cardIcon} ${designVisible} ${pointer} icon-wrapper`} onClick={isAnyAdmin && selectedProgramId ? onClick : () => onRewardsRedirect(false, true)}>
            {<FontAwesomeIcon icon={isAnyAdmin && selectedProgramId ? faSlidersH : (isBeneficiary || (!isFreemium && !isBeneficiary) ? faCog : faSlidersH)} style={{ visibility: showcog ? 'visible' : 'hidden' }}/> }
          </div>}
        </div>
        

        <div id="voiceflow-chat-frame"></div>

        {!isAnyAdmin && newAiName && selectedProgramId && <DynamicFormattedMessage
          className={`${widthFull} ${widgetTextBody}`}
          style={{ color: colorWidgetTitle }}
          id={widgetTextBodyId}
          tag={HTML_TAGS.SPAN}
        />}

      </GeneralBlock>
    </div>
  );
};

export default ContactUsWidget;
