import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import UserDeclarationApi from 'api/UserDeclarationsApi';
import {
  buildDeclarationFieldsOnCreate,
  prepareDeclarationData,
  resolveUserDeclarationCreateErrorMessage
} from 'services/UserDeclarationServices';
import { REQUIRED } from 'constants/validation';
import { USER_DECLARATIONS_ROUTE, WALL_BENEFICIARY_DECLARATIONS_ROUTE } from 'constants/routes';
import { CHALLENGE, PROGRAM_TYPES, SPONSORSHIP } from 'constants/wall/launch';
import ProgramsApi from 'api/ProgramsApi';
import { validateTextInput } from 'services/FormServices';
import { handleApiFormValidation } from 'utils/validationUtils';
import useProgramDetails from 'hooks/programs/useProgramDetails';
import { resolveTxt } from 'dns';
import { sl } from 'date-fns/locale';

const userDeclarationApi = new UserDeclarationApi();
const programsApi = new ProgramsApi();

/**
 * Hook used to create a single user declaration.
 */
const useCreateUserDeclaration = isBeneficiary => {
  const history = useHistory();
  const intl = useIntl();
  const [selectedProgram, setSelectedProgram] = useState<any>();
  const [programData, setProgramData] = useState<any>();
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<any | undefined>();
  const [errors, setErrors] = useState<any>({});
  const [reloadKey, setReloadKey] = useState(0);
  const [declarationFields, setDeclarationFields] = useState({ formFields: [], loading: false });

  const onProgramChange = useCallback(
    program => {
      setSelectedBeneficiaryId(undefined);
      setSelectedProgram(program);
      setErrors({
        ...errors,
        programError: undefined
      });
    },
    [errors]
  );

  const onBeneficiaryChange = useCallback(
    beneficiary => {
      setSelectedBeneficiaryId(beneficiary.uuid);
      setErrors({
        ...errors,
        beneficiaryError: undefined
      });
    },
    [errors]
  );

  useEffect(() => {
    // improvement idea: use LRU caching
    const loadFields = async programId => {
      try {
        
        const { resultsFormFields, ...rest } = await programsApi.getProgramDetails(programId);
       
        // const detail = useProgramDetails(programId);
        setProgramData(rest);
        
        console.log("RESULTFORMFIELDS", rest)
        console.log("PARAMS:"+ rest.type + " "+ rest.cube.goals[0].measurementName)
        const formFields = buildDeclarationFieldsOnCreate(resultsFormFields, rest.type , rest.cube.goals[0].measurementName);
        console.log(formFields)
        console.log("KHREJ")
        setDeclarationFields({ formFields, loading: false });
      } catch (e) {
        toast(intl.formatMessage({ id: 'wall.userDeclaration.fields.error.failedToLoad' }));
        setDeclarationFields({ formFields: [], loading: false });
      }
    };

    setDeclarationFields({ formFields: [], loading: !!selectedProgram });

    if (selectedProgram) {
      loadFields(selectedProgram.id);
    }
  }, [selectedProgram]);

  const onValidate = async (values, form) => {
    console.log("IF0")
    const { setErrors: setFormErrors, errors: formErrors } = form;
    const { productName: productField } = values;
    if (
      programData.type != PROGRAM_TYPES[SPONSORSHIP] &&
      (!productField || !!productField.productId === !!productField.otherProductName)
    ) {
      console.log("IF1")
      const productName = productField.otherProductName ? validateTextInput(productField.otherProductName) : REQUIRED;
      setFormErrors({ ...formErrors, productName });
      return;
    }
    if (!isBeneficiary && (!selectedProgram || !selectedBeneficiaryId)) {
      console.log("IF2")
      const programError = !selectedProgram && REQUIRED;
      const beneficiaryError = !selectedBeneficiaryId && REQUIRED;
      setErrors({
        programError,
        beneficiaryError
      });
      return;
    }
    console.log("PARAMS DECLARATION ")
    console.log(values)
    console.log(form)
    await onSubmitData(values, form);
  };

  const onSubmitData = async (values, { resetForm, ...rest }) => {
    
    const beneficiaryData = {
      programId: selectedProgram.id,
      uuid: selectedBeneficiaryId
    };
    if (isBeneficiary) {
      beneficiaryData.uuid = null;
    }

    try {
      console.log("debut")
      console.log(values)
      console.log(beneficiaryData)
      console.log(programData)
      const declarationData = prepareDeclarationData(values,beneficiaryData,programData)
      console.log("YA RAB")
      console.log(declarationData)
      await userDeclarationApi.createDeclaration(declarationData);
      resetForm();
      setReloadKey(reloadKey + 1);
      toast(intl.formatMessage({ id: 'wall.userDeclaration.add.success' }));
      setSelectedProgram(null);
      history.push(isBeneficiary ? WALL_BENEFICIARY_DECLARATIONS_ROUTE : USER_DECLARATIONS_ROUTE);
    } catch ({ response }) {
      const errorMessage = resolveUserDeclarationCreateErrorMessage(response);
      handleApiFormValidation(rest, values, response);

      toast(intl.formatMessage({ id: errorMessage }));
    }
  };

  return {
    selectedProgram,
    onProgramChange,
    declarationFields,
    onBeneficiaryChange,
    errors,
    reloadKey,
    programData,
    onValidate
  };
};

export default useCreateUserDeclaration;
