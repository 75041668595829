import axios from 'axios';

class AIRAGApi {
  async uploadFiles(files, companyName, isCommon) {
    const formData = new FormData();
    let filename = "x";

    files.forEach((file, index) => {
      if (file) {
        filename = file.name;
        formData.append('files', file);
        formData.append('paths', isCommon ? `Common/${file.name}` : `${companyName}/${file.name}`);
      }
    });

    try {
      const response = await axios.post('http://localhost:8094/local_upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          company_id: companyName,
          filename: filename,
          isCommon: isCommon ? true : false
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  }
}

export default new AIRAGApi();
