import React from 'react';

const BannerPreview = ({ highlightedIndex }) => {
  return (
    <div style={{ display: 'flex', bottom: 100, left: 300, zIndex: 999, backgroundColor: 'white', padding: '10px', border: '1px solid black' }}>
      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Prévisualisation bannières</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '120px', height: '60px', backgroundColor: highlightedIndex === 0 ? 'purple' : 'gray', margin: '8px', border: highlightedIndex === 0 ? '2px solid black' : '1px solid transparent', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: highlightedIndex === 0 ? 'white' : 'white', fontWeight: highlightedIndex === 0 ? 'bold' : 'normal', textAlign: 'center' }}>Banner 1</p>
          </div>
          <div style={{ width: '120px', height: '60px', backgroundColor: highlightedIndex === 1 ? 'purple' : 'gray', margin: '8px', border: highlightedIndex === 1 ? '2px solid black' : '1px solid transparent', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: highlightedIndex === 1 ? 'white' : 'white', fontWeight: highlightedIndex === 1 ? 'bold' : 'normal', textAlign: 'center' }}>Banner 2</p>
          </div>
          <div style={{ width: '120px', height: '60px', backgroundColor: highlightedIndex === 2 ? 'purple' : 'gray', margin: '8px', border: highlightedIndex === 2 ? '2px solid black' : '1px solid transparent', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: highlightedIndex === 2 ? 'white' : 'white', fontWeight: highlightedIndex === 2 ? 'bold' : 'normal', textAlign: 'center' }}>Banner 3</p>
          </div>
        </div>
        <div style={{ width: '2px', backgroundColor: 'gray', margin: '8px 20px' }}></div> {/* Vertical gray line */}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <div style={{ width: '120px', height: '60px', visibility: 'hidden' }}></div> {/* Invisible rectangle to adjust position */}
          <div style={{ width: '120px', height: '60px', backgroundColor: highlightedIndex === 3 ? 'purple' : 'gray', margin: '8px', border: highlightedIndex === 3 ? '2px solid black' : '1px solid transparent', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ color: highlightedIndex === 3 ? 'white' : 'white', fontWeight: highlightedIndex === 3 ? 'bold' : 'normal', textAlign: 'center' }}>Banner 4</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPreview;
