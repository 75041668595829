import { useEffect, useState } from 'react';

import ProgramsApi from 'api/ProgramsApi';

const programsApi = new ProgramsApi();
/**
 * Hook used to load program details
 * @param programId
 * @param onError
 */
const useProgramDetails = (programId: number, onError?) => {
  const [programDetails, setProgramDetails] = useState<any>({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      setProgramDetails({});
      setLoading(true);

      try {
        const program = await programsApi.getProgramDetails(programId);
        setProgramDetails(program);
        
      } catch (e) {
        onError && onError(e);
      }

      setLoading(false);
    };

    programId && load();
  }, [programId]);

  return { programDetails, isLoading };
};

export default useProgramDetails;
