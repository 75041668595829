import React from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { useCubeDetailsForPdfCreation } from 'hooks/pdf/useCubeDetailsForPdfCreation';
import { useProgramDetailsForPdfCreation } from 'hooks/pdf/useProgramDetailsForPdfCreation';
/**
 * Template for program dynamic pdf creation
 *
 * @param launchData
 * @param userData
 * @param programType
 * @constructor
 */
const DynamicTCDocument = ({ launchData, userData, programType }) => {
  const {
    mechanisms,
    correlatedGoals,
    translationsGoals,
    cube,
    declarationManualValidation,
    resultsManualValidation,
    pointsAllocationTranslation,
    pointsSpendingTranslation
  } = useCubeDetailsForPdfCreation(launchData);

  const {
    dateNow,
    programName,
    companyName,
    programStartingDate,
    programEndingDate,
    programBudget,
    stateAndCountry
  } = useProgramDetailsForPdfCreation(launchData, userData);
  const programTypeTitle = programType.charAt(0).toUpperCase() + programType.slice(1);
  const goalsJoin = correlatedGoals ? ' and ' : ' or ';

  const styles = StyleSheet.create({
    page: {
      padding: 30
    },
    heading: {
      textAlign: 'center',
      fontSize: 10,
      marginBottom: 20,
      paddingLeft: 15,
      paddingRight: 15,
      fontFamily: 'Helvetica-Bold'
    },
    subtitle: {
      fontSize: 10,
      fontFamily: 'Helvetica-Bold',
      marginBottom: 2
    },
    text: {
      fontSize: 10,
      lineHeight: 1.4,
      fontFamily: 'Helvetica',
      textAlign: 'justify',
      marginBottom: 10
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/*TC title*/}
        <View>
          <Text style={styles.heading}>{programTypeTitle.toUpperCase()} PROGRAM AGREEMENT</Text>
          {/*TC subtitle text*/}
          <View style={{ marginBottom: 20 }}>
            <View style={styles.text}>
              <Text>
                Created on {dateNow} for {programName || ''}
              </Text>
              <Text>
                This {programTypeTitle} Program Agreement [known hereafter as the “Agreement”] is executed on this{' '}
                {dateNow} between {companyName || ''}, known hereafter as the “Company” and any registered participant
                of this Program, known hereafter as the “Beneficiary”.
              </Text>
            </View>
          </View>
          {/*TC subtitle*/}
          <View style={styles.subtitle}>
            <Text>Terms of Agreement</Text>
          </View>
          {/*TC paragraph 1 title*/}
          <View style={styles.subtitle}>
            <Text>1. Beneficiary Obligations</Text>
          </View>
          {/*TC paragraph 1 text -> program's cube details*/}
          <View style={styles.text}>
            <Text>This is a {programType} program.</Text>
            <Text>The mechanism is based on: {(mechanisms && mechanisms.join(goalsJoin)) || ''}</Text>
            {(correlatedGoals && <Text>To get points, you need to achieve all of the following goals:</Text>) || (
              <Text>To get points, you need to achieve one of the following goals:</Text>
            )}
            <Text>{(translationsGoals && translationsGoals.join(`\n${goalsJoin}\n`)) || ''}</Text>
            {cube && cube.rewardPeopleManagerAccepted && (
              <Text>
                Program managers will get {cube.rewardPeopleManagers || ''}% of the points your team members earn.
              </Text>
            )}
            {(declarationManualValidation || resultsManualValidation) && (
              <Text>The results (your declarations) are manually validated by an administrator.</Text>
            )}
            <Text>The points are awarded {pointsAllocationTranslation || ''}</Text>
            <Text>You can convert them {pointsSpendingTranslation || ''}</Text>
          </View>
          {/*TC paragraph 2 title*/}
          <View style={styles.subtitle}>
            <Text>2. Company Obligations</Text>
          </View>
          {/*TC paragraph 2 text*/}
          <View style={styles.text}>
            <Text>The Company must pay the points before they can be converted by any Beneficiary.</Text>
            <Text>
              The Company cannot stop nor cancel the Program either before the Program’s official ending date or before
              the Program reaches the maximum budget on points conversion.
            </Text>
          </View>
        </View>
        {/*TC paragraph 4 text*/}
        <View>
          {/*TC paragraph 3 title*/}
          <View style={styles.subtitle}>
            <Text>3. Company Trademarks and Materials</Text>
          </View>
          {/*TC paragraph 3 text*/}
          <View style={styles.text}>
            <Text>
              Subject to the terms and conditions of this Agreement, the Company grants the Beneficiary the right to use
              the Company&apos;s trade names, logo designs, trademarks, and company descriptions as provided in Company
              marketing materials. These assets may be used in any medium of advertising, promotional products, or
              marketing materials distributed solely in connection with {programName || ''}. Beneficiary agrees to use
              materials according to Company&apos;s trademark usage guidelines.
            </Text>
          </View>
          {/*TC paragraph 4 title*/}
          <View style={styles.subtitle}>
            <Text>4. Indemnity</Text>
          </View>
          <View style={styles.text}>
            <Text>
              4.1 The Company will indemnify, defend, and hold the Beneficiary harmless from and against any claims
              relating directly or indirectly to, or arising out of, content posted on the Company&apos;s respective
              website, use of Company materials, or use of Company&apos;s logos and trademarks.
            </Text>
          </View>
          <View style={styles.text}>
            <Text>
              4.2 The Beneficiary will not be responsible for damage to or loss of property belonging to the Company,
              its employees, contractors, or agents or for personal injury to the Company&apos;s employees, contractors,
              agents, directors, or invitees except to the extent that claims may be solely and directly attributed to
              willful misconduct or gross negligence of the Beneficiary and Beneficiary&apos;s employers, directors, or
              officers.
            </Text>
            <Text>
              4.3 Company will give Beneficiary prompt written notice of any suit or claim that comes within the purview
              of these indemnities.
            </Text>
          </View>
          {/*TC paragraph 5 title*/}
          <View style={styles.subtitle}>
            <Text>5. Limitation of Liability</Text>
          </View>
          {/*TC paragraph 5 text*/}
          <View style={styles.text}>
            <Text>
              In no event shall either party be liable to the other party for any consequential, incidental, indirect,
              or punitive damages regardless of whether such liability results from breach of contract, breach of
              warranties, tort, strict liability, or otherwise.
            </Text>
          </View>
          {/*TC paragraph 6 title*/}
          <View style={styles.subtitle}>
            <Text>6. Term and Termination</Text>
          </View>
        </View>

        {/*TC paragraph 7 text*/}
        <View>
          {/*TC paragraph 6 text*/}
          <View style={styles.text}>
            <Text>
              6.1 This Agreement will be valid for the period of {programStartingDate || ''}
              {programEndingDate ? ` to ${programEndingDate}` : ''} or where the amount
            </Text>
            <Text>paid to points conversion (“Maximum Budget”) reaches {programBudget || ''}</Text>
            <Text>
              6.2 The Beneficiary may terminate this Agreement at any time for any reason. In the event that the
              Beneficiary terminates this Agreement for any reason other than Company&apos;s breach of Agreement, the
              Beneficiary shall refund any fees received from the Company and return any materials, equipment, hardware,
              or software loaned by the Company for the event, at the Company&apos;s expense.
            </Text>
            <Text>
              6.3 The Company may terminate this Agreement for breach of Agreement by the Beneficiary after giving
              Beneficiary at least ten (10) days prior written notice specifying the nature of the breach and giving the
              Beneficiary at least ten (10) days to resolve such breach. If breach occurs less than ten (10) days before
              the event, Company may terminate this Agreement if breach is not resolved by the first day of the event.
            </Text>
          </View>
          {/*TC paragraph 7 title*/}
          <View style={styles.subtitle}>
            <Text>7. Disagreement</Text>
          </View>
          <View style={styles.text}>
            <Text>
              In the case of a disagreement at any point, the parties will refer the matter to an independent arbitrator
              appointed by mutual agreement.
            </Text>
          </View>
          {/*TC paragraph 8 title*/}
          <View style={styles.subtitle}>
            <Text>8. Miscellaneous</Text>
          </View>
          {/*TC paragraph 8 text*/}
          <View style={styles.text}>
            <Text>
              8.1 This Agreement will supersede any or all prior oral or written forms of understanding between the
              Company and Beneficiary. This Agreement may not be amended or modified except when one or both parties
              execute amendments in writing and amendments are signed by both parties.
            </Text>
            <Text>
              8.2 This Agreement shall be governed by and executed in accordance with the laws of{' '}
              {stateAndCountry || ''} applicable to agreements made and to be performed entirely within this state.
            </Text>
            <Text style={{ marginBottom: 10 }}>
              8.3 The terms and conditions of this Agreement shall not be divulged to any third party without prior
              written approval from both parties.
            </Text>
          </View>
          {/*TC Ending date*/}
          <View style={styles.text}>
            <Text>{dateNow}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export { DynamicTCDocument };
