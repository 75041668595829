// import React, { useState, useRef, useEffect, useContext } from 'react';
// import { FormattedMessage, useIntl } from 'react-intl';
// import HeadingAtom from 'components/atoms/ui/Heading';
// import style from 'assets/style/components/launch/Launch.module.scss';
// import style2 from 'assets/style/components/wall/GeneralWallStructure.module.scss';
// import style3 from 'assets/style/common/VericalTabs.module.scss';
// import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
// import Button from 'components/atoms/ui/Button';
// import AIRAGApi from 'api/IA API/AIRagApi';
// import Multiselect from 'multiselect-react-dropdown'; // Import Multiselect here
// import getUserProgramsAsync from 'api/UsersApi'
// import FilesApi from 'api/FilesApi';
// import UserApi from 'api/UsersApi';
// import { IUserProgramsSearchCriteria } from 'interfaces/api/IUserProgramsSearchCriteria';
// import { UserContext } from 'components/App';
// import { stringify } from 'querystring';

// const questions = [
//   'ai.question1',
//   'ai.question2',
//   'ai.question3',
//   'ai.question4',
//   'ai.question5',
//   'ai.question6',
//   'ai.question7'
// ];

// const questionsCOMMON = [
//   'ai.common.question1'
// ];

// const AiRagComponent = ({ isHyperAdmin, companyName }) => {
//   const [uploadedFiles, setUploadedFiles] = useState(Array(questions.length).fill(null));
//   const [uploadedCommonFiles, setUploadedCommonFiles] = useState(Array(questionsCOMMON.length).fill(null));
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const fileInputRefs = useRef([]);
//   const commonFileInputRefs = useRef([]);
//   const intl = useIntl();
//   const [programOptions, setProgramOptions] = useState([]); // State to hold program options
//   const [selectedProgram, setSelectedProgram] = useState(null); // State to hold selected program
//   const { userData } = useContext(UserContext);
//   // useEffect(() => {
//   //   // Fetch program options (assuming this data is fetched from somewhere)
//   //   const userApi = new UserApi();
  
//   //   const useruuid = String(userData.uuid)
//   //   const searchCriteria: IUserProgramsSearchCriteria = {
//   //     uuid: useruuid, // Replace with the actual user UUID
//   //     // other search criteria properties if needed
//   // };
//   //   const response = userApi.getUserProgramsAsync(searchCriteria);
//   //   setProgramOptions(response);


//   // }, []);

//   useEffect(() => {
//     const fetchProgramOptions = async () => {
//       try {
//         const userApi = new UserApi();
//         const useruuid = String(userData.uuid);
//         const searchCriteria: IUserProgramsSearchCriteria = {
//           uuid: useruuid,
//           // other search criteria properties if needed
//         };
//         const response = await userApi.getUserProgramsAsync(searchCriteria);
//         setProgramOptions(response.data); // Assuming response.data contains the program options
//         console.log(response)
//       } catch (error) {
//         console.error('Error fetching user programs:', error);
//       }
//     };

//     fetchProgramOptions();
//   }, [userData]);

//   const handleProgramSelect = (selectedList) => {
//     setSelectedProgram(selectedList[0]); // Assuming it's a single select
//   };

//   // Rest of your component code...

//   const handleSubmit = async (files, isCommon) => {
//     setFormSubmitted(true);
//     try {
//       const response = await AIRAGApi.uploadFiles(files, companyName, isCommon);
//       console.log('Upload response:', response);
//     } catch (error) {
//       console.error('Error uploading files:', error);
//     }
//   };

//   const handleRedirect = () => {
//     // history.push('/');
//     console.log("ZER")
//   };

 


import React, { useState, useRef, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import HeadingAtom from 'components/atoms/ui/Heading';
import style from 'assets/style/components/launch/Launch.module.scss';
import style2 from 'assets/style/components/wall/GeneralWallStructure.module.scss';
import style3 from 'assets/style/common/VericalTabs.module.scss';
import style4 from 'assets/style/components/Ai/AiRagComponent.module.scss'
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import Button from 'components/atoms/ui/Button';
import AIRAGApi from 'api/IA API/AIRagApi';
import Multiselect from 'multiselect-react-dropdown';
import UserApi from 'api/UsersApi';
import { IUserProgramsSearchCriteria } from 'interfaces/api/IUserProgramsSearchCriteria';
import { UserContext } from 'components/App';
import { IadminProgram } from './AiInterface';
import eCardStyle from 'sass-boilerplate/stylesheets/components/launch/Ecard.module.scss';
const questions = [
  'ai.question1',
  'ai.question2',
  'ai.question3',
  'ai.question4',
  'ai.question5',
  'ai.question6',
  'ai.question7'
];

const questionsCOMMON = [
  'ai.common.question1'
];


const AiRagComponent = ({ isHyperAdmin, companyName }) => {
  const [uploadedFiles, setUploadedFiles] = useState(Array(questions.length).fill(null));
  const [uploadedCommonFiles, setUploadedCommonFiles] = useState(Array(questionsCOMMON.length).fill(null));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const fileInputRefs = useRef([]);
  const commonFileInputRefs = useRef([]);
  const { formatMessage } = useIntl();
  const [programOptions, setProgramOptions] = useState<IadminProgram[]>([]); // Ensure this is always an array
  const [selectedProgram, setSelectedProgram] = useState<IadminProgram>(null); // State to hold selected program
  const { userData } = useContext(UserContext);
  
  
  function extractPrograms(platforms) {
    let programs = [];

    function recurse(platforms) {
        platforms.forEach(platform => {
            platform.programs.forEach(program => {
                if(program.iaCompany){
                programs.push({ id: program.id, name: program.name+" ("+ program.iaCompany[0].iaName+")"  });}
            });
            if (platform.subPlatforms && platform.subPlatforms.length > 0) {
                recurse(platform.subPlatforms);
            }
        });
    }

    recurse(platforms);
    return programs;
}



  useEffect(() => {
    const fetchProgramOptions = async () => {
      if (!userData?.uuid) return; // Ensure userData and uuid are available
      try {
        const userApi = new UserApi();
        const useruuid = String(userData.uuid);
        const searchCriteria: IUserProgramsSearchCriteria = {
          uuid: useruuid,
        };
        const response = await userApi.getAdminPrograms(useruuid,{});
        const result = extractPrograms(response.entries);
        console.log("Hada :"+ result)
        
        setProgramOptions(result); // Ensure response.data is always an array
        // console.log(response);
      } catch (error) {
        console.error('Error fetching user programs:', error);
      }
    };

    fetchProgramOptions();
  }, [userData]);

  const handleProgramSelect = (selectedProgram) => {
    setSelectedProgram(selectedProgram); 
  };
  
  // const handleFileChange = async (index, isCommon, event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     if (isCommon) {
  //       const newUploadedCommonFiles = [...uploadedCommonFiles];
  //       newUploadedCommonFiles[index] = files[0];
  //       setUploadedCommonFiles(newUploadedCommonFiles);
  //       try {
  //               const response = await AIRAGApi.uploadFiles(files, companyName, isCommon);
  //               console.log('Upload response:', response);
  //             } catch (error) {
  //               console.error('Error uploading files:', error);
  //             }
  //     } else {
  //       const newUploadedFiles = [...uploadedFiles];
  //       newUploadedFiles[index] = files[0];
  //       setUploadedFiles(newUploadedFiles);
  //     }
  //   }
  // };
  
  const handleFileChange = async (index, isCommon, event) => {
    const files = event.target.files;
  
    if (files.length > 0) {
      const file = files[0];
  
      if (isCommon) {
        const newUploadedCommonFiles = [...uploadedCommonFiles];
        newUploadedCommonFiles[index] = file;
        setUploadedCommonFiles(newUploadedCommonFiles);
  
        try {
          const response = await AIRAGApi.uploadFiles([file], "abibas", isCommon);
          console.log('Upload response:', response);
        } catch (error) {
          console.error('Error uploading common file:', error);
        }
      } else {
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles[index] = file;
        setUploadedFiles(newUploadedFiles);
  
        try {
          const response = await AIRAGApi.uploadFiles([file], "abibas", isCommon);
          console.log('Upload response:', response);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '50px' }}>
        <HeadingAtom className={style4.title} size="3" textId="ai.title.label" />
      </div>
      <div className={`aiSubmitText ${style4.contentWrapper} ${style4.textIntro}`}>
        <FormattedMessage id="ai.submit.text" />
      </div>
      <div className={style4.flashySlogan}>
        <FormattedMessage id="yooowin.slogan" />
      </div>
  
      <form>
        <div className={style4.multiContainer}>
          <Multiselect 
            style={{ overflowY: "hidden" }}
            key="course-select"
            options={programOptions} // Options to display in the dropdown
            onSelect={handleProgramSelect} 
            onRemove={() => setSelectedProgram(null)}
            selectedValues={selectedProgram}
            displayValue="name" // Property name to display in the dropdown options
            placeholder={formatMessage({ id: 'aiTunnel.ai.placeholder' })}
            className={`input-group ${eCardStyle.customMultiselect}`}
            showCheckbox={false}
            showArrow={true}
            customArrow={true}
            singleSelect={true}
          />
        </div>
  
        <div>
          <div className={style4.questionsWrapper}>
            {isHyperAdmin && questionsCOMMON.map((question, index) => (
              <div key={question}>
                <FormattedMessage id={question}>
                  {(text) => (
                    <div className={style4.questionContainer}>
                      <label className={style4.label}>{text}</label>
                      <input
                        type="file"
                        onChange={(event) => handleFileChange(index, true, event)}
                        ref={(el) => (commonFileInputRefs.current[index] = el)}
                        className={style4.inputFile}
                      />
                    </div>
                  )}
                </FormattedMessage>
              </div>
            ))}
          </div>
          <div className={style4.questionsColumns}>
            <div className={style4.leftColumn}>
              {selectedProgram && questions.slice(0, Math.ceil(questions.length / 2)).map((question, index) => (
                <div key={question}>
                  <FormattedMessage id={question}>
                    {(text) => (
                      <div className={style4.questionContainer}>
                        <label className={style4.AiRagQuestionLabel}>{text}</label>
                        <input
                          type="file"
                          onChange={(event) => handleFileChange(index, false, event)}
                          ref={(el) => (fileInputRefs.current[index] = el)}
                          className={style4.inputFile}
                        />
                      </div>
                    )}
                  </FormattedMessage>
                </div>
              ))}
            </div>
            <div className={style4.rightColumn}>
              {selectedProgram && questions.slice(Math.ceil(questions.length / 2)).map((question, index) => (
                <div key={question}>
                  <FormattedMessage id={question}>
                    {(text) => (
                      <div className={style4.questionContainer}>
                        <label className={style4.AiRagQuestionLabel}>{text}</label>
                        <input
                          type="file"
                          onChange={(event) => handleFileChange(index + Math.ceil(questions.length / 2), false, event)}
                          ref={(el) => (fileInputRefs.current[index + Math.ceil(questions.length / 2)] = el)}
                          className={style4.inputFile}
                        />
                      </div>
                    )}
                  </FormattedMessage>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        <div className={style3.productsWrapperSubmitBtn} style={{ marginBottom: 10 }}>
          <DynamicFormattedMessage
            tag={Button}
            id="form.submit.button"
          />
        </div>
      </form>
    </React.Fragment>
  );
}

export default AiRagComponent;

