// import React, { useState, useRef, useEffect, useContext } from 'react';
// import { FormattedMessage, useIntl } from 'react-intl';
// import './AiComponentPage.css'; // Ensure the path is correct
// import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css'; // Import the styles for react-tabs
// import LeftSideLayout from 'components/organisms/layouts/LeftSideLayout';
// import { WALL_TYPE } from 'constants/general';
// import HeadingAtom from 'components/atoms/ui/Heading';
// import style from 'assets/style/components/launch/Launch.module.scss';
// import style2 from 'assets/style/components/wall/GeneralWallStructure.module.scss';
// import style3 from 'assets/style/common/VericalTabs.module.scss';
// import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
// import Button from 'components/atoms/ui/Button';
// import { getUserAuthorizations } from 'services/security/accessServices';
// import { useUserRole } from 'hooks/user/useUserRole';
// import { UserContext } from 'components/App';
// import AIRAGApi from 'api/IA API/AIRagApi';

// const questions = [
//   'ai.question1',
//   'ai.question2',
//   'ai.question3',
//   'ai.question4',
//   'ai.question5',
//   'ai.question6',
//   'ai.question7'
// ];

// const questionsCOMMON = [
//   'ai.common.question1'
// ];

// const loadUploadedFilesFromLocalStorage = () => {
//   const uploadedFiles = localStorage.getItem('aiFiles');
//   return uploadedFiles ? JSON.parse(uploadedFiles) : Array(questions.length).fill(null).map(() => '');
// };

// const saveUploadedFilesToLocalStorage = (files) => {
//   localStorage.setItem('aiFiles', JSON.stringify(files));
// };

// const AiComponentPage = () => {
//   const [uploadedFiles, setUploadedFiles] = useState(Array(questions.length).fill(null));
//   const [uploadedCommonFiles, setUploadedCommonFiles] = useState(Array(questionsCOMMON.length).fill(null));
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const fileInputRefs = useRef([]);
//   const commonFileInputRefs = useRef([]);
//   const intl = useIntl();
//   const userRole = useUserRole();
//   const { isHyperAdmin } = getUserAuthorizations(userRole);
//   const { userData } = useContext(UserContext);

//   useEffect(() => {
//     setUploadedFiles(loadUploadedFilesFromLocalStorage());
//   }, []);

//   const handleFileChange = (index, event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const newUploadedFiles = [...uploadedFiles];
//       newUploadedFiles[index] = file;
//       setUploadedFiles(newUploadedFiles);
//       saveUploadedFilesToLocalStorage(newUploadedFiles);
//       handleSubmit(newUploadedFiles, false);
//     }
//   };

//   const handleCommonFileChange = (index, event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const newUploadedCommonFiles = [...uploadedCommonFiles];
//       newUploadedCommonFiles[index] = file;
//       setUploadedCommonFiles(newUploadedCommonFiles);
//       handleSubmit(newUploadedCommonFiles, true);
//     }
//   };

//   const handleRemoveFile = (index) => {
//     const newUploadedFiles = [...uploadedFiles];
//     newUploadedFiles[index] = null;
//     setUploadedFiles(newUploadedFiles);
//     saveUploadedFilesToLocalStorage(newUploadedFiles);
//     setFormSubmitted(false);
//   };

//   const handleRemoveCommonFile = (index) => {
//     const newUploadedCommonFiles = [...uploadedCommonFiles];
//     newUploadedCommonFiles[index] = null;
//     setUploadedCommonFiles(newUploadedCommonFiles);
//     setFormSubmitted(false);
//   };

//   const companyName = String(userData.companyName);

//   const handleSubmit = async (files, isCommon) => {
//     setFormSubmitted(true);
//     try {
//       const response = await AIRAGApi.uploadFiles(files, companyName, isCommon);
//       console.log('Upload response:', response);
//     } catch (error) {
//       console.error('Error uploading files:', error);
//     }
//   };

//   const handleRedirect = () => {
//     history.push('/');
//   };

//   return (
//     <div className="page-container">
//       <LeftSideLayout theme={WALL_TYPE} hasUserIcon>
//         <Tabs>
//           <TabList>
//             <Tab>Personnaliser son IA</Tab>
//             {isHyperAdmin && <Tab>Style des IA</Tab>}
//           </TabList>

//           <TabPanel>
//             <div style={{ marginLeft: '50px' }}>
//               <HeadingAtom className={style.title} size="3" textId="ai.title.label" />
//             </div>
//             <div className={`ai-submit-text ${style2.contentWrapper}`}>
//               <FormattedMessage id="ai.submit.text" />
//             </div>
//             <div className="flashy-slogan ai-submit-text">
//               <FormattedMessage id="yooowin.slogan" />
//             </div>

//             <form className="ai-form">
//               {isHyperAdmin && (
//                 <React.Fragment>
//                   {questionsCOMMON.map((questionId, index) => (
//                     <div key={index} className="form-group label-input-container">
//                       <label>{intl.formatMessage({ id: questionId })}</label>
//                       {uploadedCommonFiles[index] ? (
//                         <div className="uploaded-file">
//                           <span>{uploadedCommonFiles[index].name}</span>
//                           <button type="button" onClick={() => handleRemoveCommonFile(index)} className="remove-file-btn">x</button>
//                         </div>
//                       ) : (
//                         <div className="file-input-wrapper">
//                           <input
//                             type="file"
//                             accept=".pdf"
//                             id={`common-file-upload-${index}`}
//                             onChange={(event) => handleCommonFileChange(index, event)}
//                             ref={el => commonFileInputRefs.current[index] = el}
//                           />
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </React.Fragment>
//               )}

//               {questions.map((questionId, index) => (
//                 <div key={index} className="form-group label-input-container">
//                   <label>{intl.formatMessage({ id: questionId })}</label>
//                   {uploadedFiles[index] ? (
//                     <div className="uploaded-file">
//                       <span>{uploadedFiles[index].name}</span>
//                       <button type="button" onClick={() => handleRemoveFile(index)} className="remove-file-btn">x</button>
//                     </div>
//                   ) : (
//                     <div className="file-input-wrapper">
//                       <input
//                         type="file"
//                         accept=".pdf"
//                         id={`file-upload-${index}`}
//                         onChange={(event) => handleFileChange(index, event)}
//                         ref={el => fileInputRefs.current[index] = el}
//                       />
//                     </div>
//                   )}
//                 </div>
//               ))}

//               <div className={style3.productsWrapperSubmitBtn} style={{ marginBottom: 10 }}>
//                 <DynamicFormattedMessage
//                   tag={Button}
//                   onClick={handleRedirect}
//                   id="Retour"
//                 />
//               </div>
//             </form>
//           </TabPanel>

//           {isHyperAdmin && (
//             <TabPanel>
//               <div className="another-tab-content">
//                 <p>VIDE</p>
//               </div>
//             </TabPanel>
//           )}
//         </Tabs>
//       </LeftSideLayout>
//     </div>
//   );
// };

// export default AiComponentPage;



import React, { useState, useRef, useEffect, useContext } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import the styles for react-tabs
import LeftSideLayout from 'components/organisms/layouts/LeftSideLayout';
import { WALL_TYPE } from 'constants/general';
import { getUserAuthorizations } from 'services/security/accessServices';
import { useUserRole } from 'hooks/user/useUserRole';
import { UserContext } from 'components/App';
import AiRagComponent from './AiRagComponent';
import AiPersonnalisationComponent from './AiPersonnalisationComponent';

const AiComponentPage = () => {
  const { userData } = useContext(UserContext);
  const userRole = useUserRole();
  const { isHyperAdmin } = getUserAuthorizations(userRole);
  const companyName = String(userData.companyName); // Assuming companyName is defined somewhere

  return (
    <div className="">
      <LeftSideLayout theme={WALL_TYPE} hasUserIcon>
        <Tabs>
          <TabList>
            <Tab>Personnaliser son IA</Tab>
            {isHyperAdmin && <Tab>Style des IA</Tab>}
          </TabList>

          <TabPanel>
            <AiRagComponent isHyperAdmin={isHyperAdmin} companyName={companyName} />
          </TabPanel>

          {isHyperAdmin && (
            <TabPanel>
              <AiPersonnalisationComponent />
            </TabPanel>
          )}
        </Tabs>
      </LeftSideLayout>
    </div>
  );
};

export default AiComponentPage;

