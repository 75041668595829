import React from 'react';

import ProgramBlock from 'components/molecules/programs/ProgramBlock';

import grid from 'sass-boilerplate/stylesheets/vendors/bootstrap-grid.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/wall/Programs.module.scss';
import GenericInfiniteScroll from 'components/atoms/list/GenericInfiniteScroll';

/**
 * Handles the rendering of program blocks
 *
 * @param props
 * @constructor
 */
const ProgramsList = ({
  programs,
  isLoading,
  hasMore,
  handleLoadMore,
  userRole,
  processingInvitations = {},
  confirmInvitationRefusal,
  selectedPlatform = null
}) => (
  <GenericInfiniteScroll
    {...{
      hasMore,
      isLoading,
      loadMore: handleLoadMore,
      height: '600px',
      className: componentStyle.sectionProgramsScroll
    }}
  >
    <div className={componentStyle.sectionProgramsCol}>
      <div className={grid.row}>
        {programs.map(program => (
          <ProgramBlock
            {...{
              program,
              userRole,
              confirmInvitationRefusal,
              processingInvitation: processingInvitations[program.id],
              selectedPlatform
            }}
            key={program.id}
          />
        ))}
      </div>
    </div>
  </GenericInfiniteScroll>
);

export default ProgramsList;
